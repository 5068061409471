/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
// import useDarkMode from "@fisch0920/use-dark-mode"
// import Context from "../store/context"

import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ children }) => {
  // const darkMode = useDarkMode(false);

  // const {state} =useContext(Context)

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    // <div className={`${state.isDark ? 'dark' : ''}`}>
    <div>
    <div className={`transition-color duration-300 dark:bg-slate-700 dark:text-gray-100 min-h-screen`}>
      <Header siteTitle={data.site.siteMetadata.title} />
        <div className="m-auto max-w-7xl w-full mt-12 lg:mt-0 px-6 mb-14">
          <StaticImage className="w-full h-36 rounded-3xl shadow-xl dark:brightness-75" src="../images/engin-akyurt-92pZmhN41us-unsplash.jpg" alt="" />
        </div>
      <div
        className="container max-w-6xl mx-auto px-4"
      >
        <main className="block min-h-screen pb-48">{children}</main>
      </div>
      <Footer />
    </div>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" style={{position: "absolute", width: 0, height: 0}} width="0" height="0">
              <defs>
                <symbol id="icon-dark-mode" viewBox="0 0 24 24">
                  <title>Dark Mode</title>
                  <path d="M12,3c-4.97,0-9,4.03-9,9s4.03,9,9,9s9-4.03,9-9c0-0.46-0.04-0.92-0.1-1.36c-0.98,1.37-2.58,2.26-4.4,2.26 c-2.98,0-5.4-2.42-5.4-5.4c0-1.81,0.89-3.42,2.26-4.4C12.92,3.04,12.46,3,12,3L12,3z"/>
                </symbol>
              </defs>
            </svg>
            
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg"  style={{position: "absolute", width: 0, height: 0}} width="0" height="0">
              <defs>
                <symbol id="icon-light-mode" viewBox="0 0 24 24">
                  <title>Light Mode</title>
                  <path d="M12,7c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5S14.76,7,12,7L12,7z M2,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0 c-0.55,0-1,0.45-1,1S1.45,13,2,13z M20,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S19.45,13,20,13z M11,2v2 c0,0.55,0.45,1,1,1s1-0.45,1-1V2c0-0.55-0.45-1-1-1S11,1.45,11,2z M11,20v2c0,0.55,0.45,1,1,1s1-0.45,1-1v-2c0-0.55-0.45-1-1-1 C11.45,19,11,19.45,11,20z M5.99,4.58c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06 c0.39,0.39,1.03,0.39,1.41,0s0.39-1.03,0-1.41L5.99,4.58z M18.36,16.95c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41 l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0c0.39-0.39,0.39-1.03,0-1.41L18.36,16.95z M19.42,5.99c0.39-0.39,0.39-1.03,0-1.41 c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L19.42,5.99z M7.05,18.36 c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L7.05,18.36z"/>
                </symbol>
              </defs>
            </svg>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg"  style={{position: "absolute", width: 0, height: 0}} width="0" height="0">
              <defs>
<symbol id="logo" viewBox="0 0 289 108">
    <path d="M5.6,9.962L5.6,17.257C5.6,17.634 5.832,17.89 6.297,18.026C6.529,18.09 6.676,18.164 6.736,18.248C6.796,18.333 6.826,18.519 6.826,18.807C6.826,19.256 6.792,19.52 6.724,19.6C6.656,19.68 6.489,19.721 6.225,19.721L0.733,19.721C0.429,19.721 0.23,19.658 0.138,19.534C0.046,19.41 0,19.156 0,18.771C0,18.515 0.022,18.353 0.066,18.284C0.11,18.216 0.24,18.134 0.457,18.038C0.753,17.91 0.937,17.8 1.009,17.708C1.082,17.615 1.118,17.477 1.118,17.293L1.118,2.776C1.118,2.568 1.076,2.407 0.991,2.295C0.907,2.183 0.725,2.067 0.445,1.947C0.244,1.859 0.144,1.662 0.144,1.358C0.144,1.326 0.148,1.15 0.156,0.829C0.204,0.493 0.397,0.324 0.733,0.324L8.232,0.324C8.456,0.324 8.612,0.361 8.701,0.433C8.789,0.505 8.857,0.681 8.905,0.961C8.913,1.009 8.925,1.078 8.941,1.166L9.482,3.834C9.514,4.002 9.53,4.146 9.53,4.266C9.53,4.523 9.338,4.651 8.953,4.651C8.44,4.651 8.12,4.555 7.992,4.362C7.863,4.17 7.663,3.693 7.391,2.932C7.222,2.468 7.056,2.155 6.892,1.995C6.728,1.835 6.473,1.755 6.129,1.755C6.033,1.755 5.856,1.767 5.6,1.791L5.6,8.653L6.213,8.653C6.533,8.653 6.756,8.578 6.88,8.43C7.004,8.282 7.094,8.028 7.15,7.667C7.222,7.259 7.291,6.996 7.355,6.88C7.419,6.764 7.683,6.706 8.148,6.706C8.468,6.706 8.663,6.738 8.731,6.802C8.799,6.866 8.833,6.99 8.833,7.174L8.833,11.525C8.833,11.709 8.801,11.829 8.737,11.885C8.673,11.941 8.468,11.969 8.124,11.969C7.707,11.969 7.445,11.901 7.337,11.765C7.228,11.629 7.146,11.296 7.09,10.768C7.026,10.231 6.734,9.962 6.213,9.962L5.6,9.962Z" style={{fillRule: 'nonzero'}} />
    <path d="M15.719,3.137C17.225,3.137 18.483,3.812 19.492,5.161C20.502,6.511 21.006,8.516 21.006,11.176C21.006,14.044 20.494,16.213 19.468,17.684C18.443,19.154 17.165,19.889 15.635,19.889C14.577,19.889 13.66,19.552 12.883,18.879C12.106,18.206 11.507,17.309 11.086,16.187C10.665,15.066 10.455,13.532 10.455,11.585C10.455,8.805 10.938,6.702 11.903,5.276C12.869,3.85 14.14,3.137 15.719,3.137ZM16.584,6.153C16.584,5.159 16.296,4.663 15.719,4.663C15.094,4.663 14.781,5.272 14.781,6.489L14.781,16.476C14.781,17.686 15.066,18.29 15.635,18.29C16.268,18.29 16.584,17.686 16.584,16.476L16.584,6.153Z" style={{fillRule: 'nonzero'}} />
    <path d="M26.438,6.501C26.534,6.309 26.651,5.973 26.787,5.492C27.091,4.45 27.39,3.797 27.682,3.533C27.974,3.269 28.297,3.137 28.649,3.137C29.13,3.137 29.493,3.365 29.737,3.822C29.981,4.278 30.104,4.851 30.104,5.54C30.104,7.022 29.687,7.763 28.854,7.763C28.437,7.763 28.117,7.519 27.892,7.03C27.756,6.75 27.616,6.61 27.472,6.61C27.111,6.61 26.931,7.006 26.931,7.799L26.931,17.401C26.931,17.706 27.071,17.93 27.352,18.074C27.632,18.21 27.772,18.431 27.772,18.735L27.772,19.276C27.772,19.468 27.75,19.59 27.706,19.642C27.662,19.694 27.536,19.721 27.328,19.721L22.797,19.721C22.485,19.721 22.328,19.572 22.328,19.276L22.328,18.615C22.328,18.391 22.396,18.242 22.533,18.17C22.805,18.034 22.941,17.826 22.941,17.545L22.941,5.444C22.941,5.171 22.845,4.975 22.653,4.855C22.444,4.735 22.324,4.641 22.292,4.573C22.26,4.505 22.244,4.298 22.244,3.954C22.244,3.665 22.286,3.485 22.37,3.413C22.454,3.341 22.597,3.305 22.797,3.305L26.042,3.305C26.194,3.305 26.298,3.347 26.354,3.431C26.41,3.515 26.438,3.717 26.438,4.038L26.438,6.501Z" style={{fillRule: 'nonzero'}} />
    <path d="M31.486,4.951L31.017,4.951C30.881,4.951 30.795,4.935 30.758,4.903C30.722,4.871 30.704,4.783 30.704,4.639L30.704,3.773C30.704,3.613 30.722,3.509 30.758,3.461C30.795,3.413 30.901,3.377 31.077,3.353C31.654,3.265 32.235,2.944 32.819,2.391C33.404,1.839 33.753,1.246 33.865,0.613C33.905,0.389 33.955,0.25 34.015,0.198C34.075,0.146 34.197,0.12 34.382,0.12L35.163,0.12C35.371,0.12 35.475,0.26 35.475,0.541L35.475,3.305L36.22,3.305C36.501,3.305 36.641,3.437 36.641,3.701L36.641,4.567C36.641,4.823 36.501,4.951 36.22,4.951L35.475,4.951L35.475,17.401C35.475,17.826 35.664,18.13 36.04,18.314C36.224,18.403 36.345,18.511 36.401,18.639C36.457,18.767 36.485,18.999 36.485,19.336C36.485,19.544 36.471,19.66 36.443,19.684C36.415,19.709 36.317,19.721 36.148,19.721L32.351,19.721C31.774,19.208 31.486,18.435 31.486,17.401L31.486,4.951Z" style={{fillRule: 'nonzero'}} />
    <path d="M51.915,8.665C53.381,9.177 54.391,9.812 54.943,10.569C55.496,11.326 55.773,12.37 55.773,13.7C55.773,15.727 55.264,17.237 54.246,18.23C53.229,19.224 51.655,19.721 49.524,19.721L43.647,19.721C43.407,19.721 43.287,19.596 43.287,19.348L43.287,18.627C43.287,18.427 43.395,18.278 43.611,18.182C44.068,17.966 44.296,17.65 44.296,17.233L44.296,2.728C44.296,2.528 44.264,2.391 44.2,2.319C44.136,2.247 43.944,2.135 43.623,1.983C43.463,1.911 43.369,1.831 43.341,1.743C43.313,1.654 43.299,1.438 43.299,1.094C43.299,0.789 43.341,0.585 43.425,0.481C43.509,0.377 43.655,0.324 43.863,0.324L50.449,0.324C52.035,0.324 53.253,0.729 54.102,1.538C54.952,2.347 55.376,3.377 55.376,4.627C55.376,5.668 55.042,6.541 54.373,7.246C53.704,7.952 52.885,8.424 51.915,8.665ZM48.767,1.682L48.767,7.992L49.403,7.992C49.804,7.992 50.119,7.875 50.347,7.643C50.575,7.411 50.707,7.024 50.743,6.483C50.779,5.943 50.797,5.26 50.797,4.434C50.797,3.281 50.687,2.534 50.467,2.193C50.247,1.853 49.892,1.682 49.403,1.682L48.767,1.682ZM48.767,9.362L48.767,18.339C49.504,18.339 49.998,18.238 50.251,18.038C50.503,17.838 50.651,17.441 50.695,16.848C50.739,16.256 50.761,15.254 50.761,13.844C50.761,13.556 50.749,12.901 50.725,11.879C50.701,10.858 50.653,10.243 50.581,10.035C50.509,9.826 50.383,9.662 50.203,9.542C50.022,9.422 49.756,9.362 49.403,9.362L48.767,9.362Z" style={{fillRule: 'nonzero'}} />
    <path d="M61.421,11.272L61.421,16.187C61.421,16.836 61.547,17.345 61.799,17.714C62.052,18.082 62.374,18.266 62.767,18.266C63.376,18.266 63.862,18.008 64.227,17.491C64.591,16.975 64.786,16.268 64.81,15.37C64.818,14.89 64.864,14.601 64.948,14.505C65.032,14.409 65.323,14.361 65.819,14.361C66.252,14.361 66.526,14.405 66.642,14.493C66.759,14.581 66.817,14.801 66.817,15.154C66.817,16.388 66.416,17.485 65.615,18.447C64.814,19.408 63.752,19.889 62.43,19.889C60.916,19.889 59.63,19.202 58.573,17.828C57.515,16.454 56.986,14.385 56.986,11.621C56.986,8.857 57.475,6.752 58.453,5.306C59.43,3.86 60.712,3.137 62.298,3.137C63.66,3.137 64.754,3.745 65.579,4.963C66.404,6.181 66.817,7.911 66.817,10.155L66.817,11.272L61.421,11.272ZM63.043,9.77L63.043,5.792C63.043,5.039 62.775,4.663 62.238,4.663C61.693,4.663 61.421,5.039 61.421,5.792L61.421,9.77L63.043,9.77Z" style={{fillRule: 'nonzero'}} />
    <path d="M72.561,4.939C73.514,3.737 74.604,3.137 75.83,3.137C76.667,3.137 77.307,3.407 77.749,3.948C78.192,4.488 78.413,5.167 78.413,5.985L78.413,17.497C78.413,17.754 78.514,17.95 78.714,18.086C78.898,18.198 78.99,18.487 78.99,18.951C78.99,19.32 78.96,19.54 78.9,19.612C78.84,19.684 78.714,19.721 78.522,19.721L75.145,19.721C74.664,19.312 74.424,18.779 74.424,18.122L74.424,6.694C74.424,6.069 74.167,5.756 73.655,5.756C73.318,5.756 72.954,5.933 72.561,6.285L72.561,17.497C72.561,17.786 72.701,18.01 72.982,18.17C73.15,18.266 73.234,18.443 73.234,18.699L73.234,19.312C73.234,19.584 73.038,19.721 72.645,19.721L68.379,19.721C68.171,19.721 68.038,19.69 67.982,19.63C67.926,19.57 67.898,19.42 67.898,19.18L67.898,18.735C67.898,18.503 67.97,18.339 68.115,18.242C68.307,18.106 68.431,17.978 68.487,17.858C68.543,17.738 68.571,17.473 68.571,17.065L68.571,5.312C68.571,5.031 68.435,4.827 68.163,4.699C68.01,4.627 67.934,4.499 67.934,4.314L67.934,3.677C67.934,3.429 68.046,3.305 68.271,3.305L72.188,3.305C72.357,3.305 72.461,3.329 72.501,3.377C72.541,3.425 72.561,3.525 72.561,3.677L72.561,4.939Z" style={{fillRule: 'nonzero'}}/>
    <path d="M86.309,4.398L86.309,2.295C86.309,1.943 86.165,1.755 85.876,1.731C85.724,1.722 85.624,1.684 85.576,1.616C85.528,1.548 85.504,1.41 85.504,1.202L85.504,0.733C85.504,0.549 85.53,0.435 85.582,0.391C85.634,0.347 85.756,0.324 85.948,0.324L89.782,0.324C89.998,0.324 90.136,0.361 90.196,0.433C90.257,0.505 90.287,0.669 90.287,0.925L90.287,17.63C90.287,17.982 90.471,18.218 90.839,18.339C91.032,18.395 91.128,18.519 91.128,18.711L91.128,19.324C91.128,19.588 91.012,19.721 90.779,19.721L86.585,19.721C86.449,19.721 86.367,19.694 86.339,19.642C86.311,19.59 86.297,19.376 86.297,18.999C86.297,18.927 86.301,18.819 86.309,18.675C85.436,19.484 84.622,19.889 83.869,19.889C81.33,19.889 80.06,17.297 80.06,12.114C80.06,8.428 80.398,6.011 81.075,4.861C81.752,3.711 82.636,3.137 83.725,3.137C84.558,3.137 85.42,3.557 86.309,4.398ZM86.309,5.756C85.94,5.532 85.64,5.42 85.408,5.42C85.143,5.42 84.929,5.546 84.765,5.798C84.6,6.051 84.518,6.333 84.518,6.646L84.518,16.572C84.518,16.868 84.594,17.119 84.747,17.323C84.899,17.527 85.091,17.63 85.323,17.63C85.54,17.63 85.758,17.535 85.978,17.347C86.199,17.159 86.309,16.981 86.309,16.812L86.309,5.756Z" style={{fillRule: 'nonzero'}} />
    <path d="M105.873,0L106.498,0C106.642,0 106.75,0.054 106.823,0.162C106.895,0.27 106.947,0.561 106.979,1.033L107.255,4.903C107.263,4.983 107.267,5.059 107.267,5.131C107.267,5.412 107.219,5.608 107.123,5.72C107.027,5.832 106.835,5.889 106.546,5.889C106.194,5.889 105.949,5.796 105.813,5.612C105.677,5.428 105.545,4.991 105.417,4.302C105.088,2.556 104.619,1.682 104.01,1.682C103.506,1.682 103.253,2.311 103.253,3.569L103.253,17.089C103.253,17.986 103.518,18.435 104.047,18.435C104.72,18.435 105.096,17.686 105.176,16.187C105.2,15.755 105.284,15.454 105.429,15.286C105.573,15.118 105.869,15.034 106.318,15.034C106.654,15.034 106.889,15.096 107.021,15.22C107.153,15.344 107.219,15.587 107.219,15.947C107.219,17.093 106.875,18.044 106.186,18.801C105.497,19.558 104.639,19.937 103.614,19.937C102.62,19.937 101.747,19.62 100.994,18.987C100.241,18.355 99.59,17.263 99.041,15.713C98.492,14.162 98.218,12.178 98.218,9.758C98.218,7.475 98.448,5.65 98.909,4.284C99.37,2.918 99.959,1.863 100.676,1.119C101.393,0.375 102.228,0.002 103.181,0C103.758,0 104.259,0.156 104.683,0.469C104.844,0.581 104.964,0.637 105.044,0.637C105.14,0.637 105.276,0.525 105.453,0.3C105.605,0.1 105.745,0 105.873,0Z" style={{fillRule: 'nonzero'}} />
    <path d="M113.396,3.137C114.902,3.137 116.16,3.812 117.17,5.161C118.179,6.511 118.684,8.516 118.684,11.176C118.684,14.044 118.171,16.213 117.145,17.684C116.12,19.154 114.842,19.889 113.312,19.889C112.254,19.889 111.337,19.552 110.56,18.879C109.783,18.206 109.184,17.309 108.763,16.187C108.343,15.066 108.132,13.532 108.132,11.585C108.132,8.805 108.615,6.702 109.581,5.276C110.546,3.85 111.818,3.137 113.396,3.137ZM114.261,6.153C114.261,5.159 113.973,4.663 113.396,4.663C112.771,4.663 112.459,5.272 112.459,6.489L112.459,16.476C112.459,17.686 112.743,18.29 113.312,18.29C113.945,18.29 114.261,17.686 114.261,16.476L114.261,6.153Z" style={{fillRule: 'nonzero'}} />
    <path d="M120.45,5.143C120.346,5.095 120.266,5.059 120.21,5.035C120.01,4.947 119.883,4.861 119.831,4.777C119.779,4.693 119.753,4.519 119.753,4.254C119.753,4.11 119.749,3.99 119.741,3.894C119.741,3.653 119.775,3.495 119.843,3.419C119.911,3.343 120.062,3.305 120.294,3.305L124.031,3.305C124.216,3.305 124.328,3.333 124.368,3.389C124.408,3.445 124.428,3.581 124.428,3.797L124.428,15.635C124.428,16.668 124.712,17.185 125.281,17.185C125.714,17.185 126.042,17.001 126.267,16.632L126.267,5.516C126.267,5.228 126.126,5.003 125.846,4.843C125.71,4.763 125.628,4.677 125.6,4.585C125.572,4.492 125.558,4.182 125.558,3.653C125.558,3.421 125.694,3.305 125.966,3.305L129.896,3.305C130.064,3.305 130.166,3.329 130.202,3.377C130.238,3.425 130.256,3.553 130.256,3.761L130.256,17.533C130.256,17.798 130.365,17.982 130.581,18.086C130.845,18.23 130.977,18.471 130.977,18.807C130.977,19.288 130.959,19.56 130.923,19.624C130.887,19.688 130.761,19.721 130.545,19.721L126.892,19.721C126.659,19.721 126.499,19.678 126.411,19.594C126.323,19.51 126.279,19.328 126.279,19.048L126.279,18.747C126.279,18.427 126.275,18.246 126.267,18.206C124.993,19.328 123.915,19.889 123.034,19.889C122.277,19.889 121.657,19.636 121.174,19.132C120.692,18.627 120.45,17.654 120.45,16.211L120.45,5.143Z" style={{fillRule: 'nonzero'}} />
    <path d="M136.53,4.939C137.483,3.737 138.572,3.137 139.798,3.137C140.635,3.137 141.275,3.407 141.718,3.948C142.161,4.488 142.382,5.167 142.382,5.985L142.382,17.497C142.382,17.754 142.482,17.95 142.682,18.086C142.867,18.198 142.959,18.487 142.959,18.951C142.959,19.32 142.929,19.54 142.869,19.612C142.809,19.684 142.682,19.721 142.49,19.721L139.113,19.721C138.633,19.312 138.392,18.779 138.392,18.122L138.392,6.694C138.392,6.069 138.136,5.756 137.623,5.756C137.287,5.756 136.922,5.933 136.53,6.285L136.53,17.497C136.53,17.786 136.67,18.01 136.95,18.17C137.118,18.266 137.202,18.443 137.202,18.699L137.202,19.312C137.202,19.584 137.006,19.721 136.614,19.721L132.347,19.721C132.139,19.721 132.007,19.69 131.951,19.63C131.895,19.57 131.867,19.42 131.867,19.18L131.867,18.735C131.867,18.503 131.939,18.339 132.083,18.242C132.275,18.106 132.4,17.978 132.456,17.858C132.512,17.738 132.54,17.473 132.54,17.065L132.54,5.312C132.54,5.031 132.404,4.827 132.131,4.699C131.979,4.627 131.903,4.499 131.903,4.314L131.903,3.677C131.903,3.429 132.015,3.305 132.239,3.305L136.157,3.305C136.325,3.305 136.429,3.329 136.469,3.377C136.509,3.425 136.53,3.525 136.53,3.677L136.53,4.939Z" style={{fillRule: 'nonzero'}} />
    <path d="M144.557,4.951L144.088,4.951C143.952,4.951 143.866,4.935 143.83,4.903C143.794,4.871 143.776,4.783 143.776,4.639L143.776,3.773C143.776,3.613 143.794,3.509 143.83,3.461C143.866,3.413 143.972,3.377 144.149,3.353C144.725,3.265 145.306,2.944 145.891,2.391C146.476,1.839 146.824,1.246 146.937,0.613C146.977,0.389 147.027,0.25 147.087,0.198C147.147,0.146 147.269,0.12 147.453,0.12L148.234,0.12C148.443,0.12 148.547,0.26 148.547,0.541L148.547,3.305L149.292,3.305C149.572,3.305 149.713,3.437 149.713,3.701L149.713,4.567C149.713,4.823 149.572,4.951 149.292,4.951L148.547,4.951L148.547,17.401C148.547,17.826 148.735,18.13 149.112,18.314C149.296,18.403 149.416,18.511 149.472,18.639C149.528,18.767 149.556,18.999 149.556,19.336C149.556,19.544 149.542,19.66 149.514,19.684C149.486,19.709 149.388,19.721 149.22,19.721L145.422,19.721C144.846,19.208 144.557,18.435 144.557,17.401L144.557,4.951Z" style={{fillRule: 'nonzero'}} />
    <path d="M156.373,12.529L157.58,6.2C157.603,6.104 157.619,6.032 157.627,5.984C157.683,5.744 157.711,5.588 157.711,5.515C157.711,5.307 157.568,5.091 157.281,4.867C157.146,4.763 157.063,4.643 157.031,4.507C156.999,4.37 156.983,4.098 156.983,3.689C156.983,3.433 157.095,3.305 157.32,3.305L159.387,3.305C159.619,3.305 159.777,3.353 159.861,3.449C159.945,3.545 159.987,3.797 159.987,4.206C159.987,4.446 159.973,4.587 159.945,4.627C159.917,4.667 159.827,4.743 159.675,4.855C159.451,5.007 159.286,5.36 159.182,5.913L157.175,16.488C156.823,18.331 156.561,19.514 156.388,20.039C156.216,20.564 155.948,21.032 155.583,21.445C155.219,21.858 154.79,22.168 154.297,22.376C153.805,22.585 153.234,22.689 152.585,22.689C151.88,22.689 151.337,22.523 150.956,22.19C150.576,21.858 150.386,21.423 150.386,20.886C150.386,20.494 150.514,20.181 150.77,19.949C151.026,19.717 151.343,19.6 151.719,19.6C152.28,19.6 152.677,19.929 152.909,20.586C153.021,20.906 153.186,21.066 153.402,21.066C153.811,21.066 154.015,20.854 154.015,20.43C154.015,20.293 153.935,19.845 153.774,19.084L151.203,6.622C151.083,6.021 150.98,5.606 150.896,5.378C150.812,5.149 150.694,4.999 150.542,4.927C150.398,4.855 150.325,4.707 150.325,4.482L150.325,3.81C150.325,3.585 150.358,3.445 150.422,3.389C150.486,3.333 150.61,3.305 150.794,3.305L155.024,3.305C155.249,3.305 155.397,3.331 155.469,3.383C155.541,3.435 155.577,3.553 155.577,3.737C155.577,4.13 155.561,4.386 155.529,4.507C155.497,4.627 155.405,4.719 155.254,4.783C155.014,4.879 154.894,5.079 154.894,5.384C154.894,5.56 154.922,5.788 154.978,6.068L156.373,12.529Z" style={{fillRule: 'nonzero'}} />
    <g transform="matrix(4.08433,0,0,4.08433,-154.541,-174.93)">
        <g transform="matrix(26.3948,0,0,26.3948,37.3348,68.9198)">
            <path d="M0.374,-0.788L0.547,-0.788C0.559,-0.788 0.566,-0.786 0.57,-0.783C0.573,-0.78 0.575,-0.771 0.575,-0.757C0.575,-0.744 0.574,-0.735 0.572,-0.73C0.569,-0.725 0.563,-0.72 0.554,-0.716C0.545,-0.712 0.54,-0.708 0.538,-0.703C0.535,-0.699 0.534,-0.691 0.534,-0.681L0.534,-0.101C0.534,-0.093 0.535,-0.086 0.537,-0.083C0.539,-0.079 0.544,-0.075 0.553,-0.07C0.565,-0.064 0.571,-0.056 0.571,-0.046C0.571,-0.021 0.57,-0.007 0.569,-0.004C0.567,-0.001 0.562,-0 0.552,-0L0.334,-0C0.325,-0 0.32,-0.008 0.32,-0.024C0.32,-0.038 0.32,-0.048 0.321,-0.054C0.322,-0.061 0.328,-0.067 0.339,-0.071C0.352,-0.076 0.358,-0.084 0.358,-0.097L0.358,-0.508L0.27,-0.176L0.216,-0.176L0.13,-0.498L0.13,-0.098C0.13,-0.085 0.136,-0.076 0.147,-0.069C0.162,-0.062 0.169,-0.049 0.169,-0.031C0.169,-0.01 0.162,-0 0.147,-0L0.042,-0C0.029,-0 0.023,-0.01 0.023,-0.03C0.023,-0.044 0.028,-0.055 0.039,-0.062C0.046,-0.068 0.051,-0.073 0.053,-0.077C0.055,-0.081 0.056,-0.087 0.056,-0.097L0.056,-0.661C0.056,-0.676 0.054,-0.688 0.052,-0.697C0.049,-0.705 0.043,-0.712 0.034,-0.717C0.024,-0.722 0.019,-0.727 0.019,-0.733L0.02,-0.754C0.02,-0.769 0.021,-0.779 0.023,-0.782C0.024,-0.786 0.029,-0.788 0.036,-0.788L0.216,-0.788L0.294,-0.486L0.374,-0.788Z" style={{fillRule: 'nonzero'}} />
        </g>
        <g transform="matrix(26.3948,0,0,26.3948,52.3401,68.9198)">
            <path d="M0.035,-0.788L0.246,-0.788C0.253,-0.788 0.258,-0.787 0.26,-0.783C0.262,-0.78 0.263,-0.771 0.263,-0.755C0.263,-0.739 0.258,-0.728 0.249,-0.723C0.241,-0.719 0.237,-0.715 0.234,-0.711C0.232,-0.707 0.23,-0.699 0.23,-0.686L0.23,-0.139C0.23,-0.124 0.235,-0.112 0.243,-0.103C0.252,-0.094 0.262,-0.089 0.275,-0.089C0.287,-0.089 0.298,-0.094 0.306,-0.104C0.315,-0.115 0.319,-0.126 0.319,-0.139L0.319,-0.686C0.319,-0.695 0.318,-0.702 0.316,-0.706C0.314,-0.71 0.31,-0.714 0.303,-0.72C0.292,-0.728 0.286,-0.741 0.286,-0.759C0.286,-0.773 0.288,-0.781 0.291,-0.784C0.294,-0.787 0.298,-0.788 0.304,-0.788L0.41,-0.788C0.421,-0.788 0.427,-0.782 0.427,-0.771L0.427,-0.747C0.427,-0.737 0.427,-0.73 0.425,-0.727C0.424,-0.725 0.42,-0.722 0.414,-0.72C0.399,-0.715 0.391,-0.704 0.391,-0.688L0.391,-0.155C0.391,-0.105 0.377,-0.065 0.348,-0.035C0.319,-0.006 0.278,0.009 0.227,0.009C0.169,0.009 0.124,-0.007 0.094,-0.038C0.064,-0.069 0.048,-0.114 0.048,-0.173L0.048,-0.688C0.048,-0.702 0.041,-0.712 0.027,-0.718C0.02,-0.722 0.015,-0.725 0.014,-0.727C0.012,-0.73 0.012,-0.736 0.012,-0.745C0.012,-0.764 0.013,-0.776 0.015,-0.781C0.017,-0.786 0.024,-0.788 0.035,-0.788Z" style={{fillRule: 'nonzero'}} />
        </g>
        <g transform="matrix(26.3948,0,0,26.3948,63.36,68.9198)">
            <path d="M0.052,-0.108L0.052,-0.7C0.051,-0.706 0.045,-0.711 0.034,-0.714C0.026,-0.717 0.02,-0.721 0.017,-0.726C0.014,-0.731 0.013,-0.742 0.013,-0.758C0.013,-0.77 0.015,-0.778 0.019,-0.782C0.023,-0.786 0.034,-0.788 0.052,-0.788L0.286,-0.788C0.352,-0.788 0.408,-0.757 0.451,-0.696C0.495,-0.634 0.517,-0.532 0.517,-0.389C0.517,-0.259 0.496,-0.162 0.454,-0.097C0.411,-0.032 0.355,-0 0.286,-0L0.028,-0C0.021,-0 0.016,-0.002 0.013,-0.005C0.01,-0.009 0.009,-0.016 0.009,-0.028C0.009,-0.042 0.009,-0.051 0.01,-0.054C0.011,-0.058 0.016,-0.062 0.024,-0.066C0.043,-0.076 0.052,-0.09 0.052,-0.108ZM0.233,-0.73L0.233,-0.054L0.259,-0.054C0.278,-0.054 0.291,-0.061 0.299,-0.076C0.308,-0.092 0.312,-0.118 0.312,-0.157L0.312,-0.656C0.312,-0.678 0.307,-0.696 0.296,-0.709C0.286,-0.723 0.271,-0.73 0.252,-0.73L0.233,-0.73Z" style={{fillRule: 'nonzero'}} />
        </g>
    </g>
    <path d="M163.936,20.754C156.369,35.572 148.572,53.899 148.737,69.689C148.846,80.057 157.351,88.387 167.719,88.278C178.087,88.17 186.417,79.664 186.308,69.296C186.143,53.506 162.006,44.747 163.936,20.754Z" style={{fill: 'rgb(20,184,166)'}} />
    <path d="M204.501,50.015C220.024,54.047 227.785,63.153 227.785,77.331C227.785,83.358 226.354,88.648 223.493,93.2C220.631,97.753 216.696,101.276 211.688,103.769C206.68,106.262 201.358,107.509 195.721,107.509C188.133,107.509 182.052,105.178 177.478,100.517C172.904,95.856 170.616,90.425 170.616,84.225C170.616,81.103 171.418,78.437 173.023,76.225C174.627,74.014 176.903,72.908 179.852,72.908C182.714,72.908 184.925,73.884 186.486,75.835C188.047,77.786 188.827,80.193 188.827,83.054C188.827,84.225 188.589,86.198 188.112,88.973C187.852,90.187 187.721,91.293 187.721,92.29C187.721,94.154 188.307,95.748 189.478,97.07C190.648,98.393 192.274,99.054 194.355,99.054C197.087,99.054 199.114,97.916 200.437,95.639C201.759,93.363 202.42,89.71 202.42,84.68C202.42,83.38 202.377,82.079 202.29,80.778L201.51,64.713C200.859,61.548 199.797,59.229 198.323,57.754C196.849,56.28 195.299,55.38 193.673,55.055C192.047,54.73 189.456,54.567 185.9,54.567L184.014,54.632C182.714,54.632 182.02,54.004 181.933,52.746L181.803,49.885C181.76,49.581 181.738,49.278 181.738,48.974C181.738,47.023 182.714,46.047 184.665,46.047C189.608,46.047 193.152,45.625 195.299,44.779C197.445,43.934 198.886,42.644 199.624,40.909C200.361,39.175 200.729,35.381 200.729,29.528C200.729,21.246 200.296,15.739 199.428,13.008C198.561,10.276 196.784,8.91 194.095,8.91C192.448,8.91 191.006,9.452 189.77,10.536C188.534,11.62 187.917,13.008 187.917,14.699C187.917,15.826 188.112,17.452 188.502,19.577C189.066,22.568 189.347,24.823 189.347,26.341C189.347,28.855 188.502,30.85 186.811,32.324C185.12,33.798 183.104,34.536 180.762,34.536C177.857,34.536 175.592,33.387 173.966,31.088C172.34,28.79 171.527,25.929 171.527,22.503C171.527,16.52 173.847,11.273 178.486,6.764C183.125,2.255 189.391,0 197.282,0C206.388,0 213.325,2.775 218.095,8.325C222.864,13.875 225.249,19.989 225.249,26.666C225.249,31.869 223.645,36.519 220.436,40.617C217.227,44.714 212.566,47.652 206.453,49.429L204.501,50.015Z" style={{fillRule: 'nonzero'}} />
    <path d="M279.887,68.811L285.415,68.811C286.802,68.811 287.713,69.049 288.146,69.526C288.58,70.003 288.797,70.957 288.797,72.388L288.797,76.225C288.797,77.353 288.623,78.111 288.276,78.502C287.93,78.892 287.106,79.087 285.805,79.087L279.887,79.087L279.887,91.314C279.887,94.219 281.122,96.322 283.594,97.623C284.938,98.317 285.61,99.531 285.61,101.265L285.61,104.192C285.61,105.146 285.436,105.753 285.09,106.013C284.743,106.273 283.941,106.403 282.683,106.403L255.302,106.403C254.001,106.403 253.199,106.176 252.895,105.72C252.592,105.265 252.44,103.737 252.44,101.135C252.44,99.574 253.091,98.425 254.391,97.688C256.039,96.734 256.863,95.412 256.863,93.721L256.863,79.087L231.107,79.087C229.72,79.087 228.896,78.924 228.636,78.599C228.376,78.273 228.246,77.524 228.246,76.352L228.23,68.801C228.23,68.02 228.295,67.402 228.425,66.947C228.555,66.492 229.487,64.876 231.22,62.101L267.761,3.624C268.413,2.591 269.206,1.891 270.14,1.525C271.075,1.159 272.781,0.976 275.258,0.976C277.648,0.976 279.018,1.116 279.365,1.398C279.713,1.68 279.887,2.45 279.887,3.707L279.887,68.811ZM256.863,36.942L236.896,68.811L256.863,68.811L256.863,36.942Z" style={{fillRule: 'nonzero'}} />
</symbol>
</defs>
</svg>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout