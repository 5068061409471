import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import Prose from "./utility/prose";

const Footer = () => (
    <StaticQuery 
    query={graphql`
    query {
        site {
            siteMetadata {
                fullname
            }
        }
    }
    `}
    render={data => (
        <footer className="text-center bg-slate-200 dark:bg-slate-800 px-4 py-8">
            <Prose>
                <p>&copy; {new Date().getFullYear()} {data.site.siteMetadata.fullname}<br />
                <Link to="/legal-notices-and-disclaimers">Legal Notices & Disclaimers</Link>
                </p>
                <p>Website provided by <a href="https://www.touchstonedistrictservices.com/" target="_blank" rel="noopener noreferrer">Touchstone</a></p>
            </Prose>
        </footer>
    )} />
)

export default Footer